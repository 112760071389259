<template>
  <div class="index" v-loading="pageLoading" ref="index">
    <div class="index-main">
      <el-carousel :interval="3000" arrow="hover" height="372px" class="banner">
        <el-carousel-item v-for="(item, index) of banner" :key="index">
          <van-image
            class="banner-item"
            width="100%"
            height="372"
            :src="item.cover"
            fit="cover"
            @click="jumpBannerLesson(item)"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="userInfo" v-if="isLogin">
        <div class="userLogo">
          <img
            :src="
              (user && user.userface) ||
              'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userLogo.jpg'
            "
          />
          <span>{{ user ? user.nickname : "用户昵称" }}</span>
        </div>
        <div class="studyTime">
          <p>
            今日学习<b>{{ studyInfo.today_time }}</b
            >分钟
          </p>
          <p>
            连续学习<b>{{ studyInfo.day_number }}</b
            >天
          </p>
        </div>
        <p class="btn" @click="jumpList('purchased')">进入已购</p>
      </div>
      <div class="noLogin" v-else>
        <div class="btn">
          <p @click="$store.commit('changeLoginShow', true)">登录</p>
          <span></span>
          <p @click="$store.commit('changeLoginShow', true)">注册</p>
        </div>
      </div>
    </div>
   
    <div class="course-item">
      <div class="module-title">
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg"
        />
        <p>职业能力认证</p>
      </div>
      <div class="course-list">
        <div
          class="course-card levelItem"
          style="width: calc((100% - 40px) / 3)"
          v-for="card of jnLesson"
          :key="card.id"
          @click="jumpLesson(card)"
        >
          <div
            class="course-card-cover"
            :style="{ height: ((width - 40) / 3 * 9) / 16 + 'px' }"
          >
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="card.is_free == 2" />
              <img :src="$vip" v-else-if="card.is_vip_flat == 1" />
              <img :src="$noVip" v-else />
            </div>
            <van-image
              class="course-cover"
              style="width: 100%; height: 100%"
              :error-icon="$tuoyuPlaceImg"
              :loading-icon="$tuoyuPlaceImg"
              :src="card.cover_mobile"
            >
              <template v-slot:error>
                <van-image
                  style="width: 100%; height: 100%"
                  fit="cover"
                  :src="$tuoyuPlaceImg"
                ></van-image>
              </template>
            </van-image>
          </div>
          <div class="course-card-detail">
            <div class="course-card-title">
              <p>{{ card.name }}</p>
              <p class="des">{{ card.description }}</p>
              <!-- <p class="lessonTm">共计{{ card.study_times }}学时（线上 学时，线下 学时）</p> -->
            </div>
            <p class="price">
              ￥<b>{{ card.price }}</b
              ><span>￥{{ card.express_price }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
document.ondragstart = function () {
  return false;
};

export default {
  name: "index",
  data() {
    return {
      pageLoading: true,
      imgScaleIndex: '',
      
      jnLesson: [],
      banner: [],
      width: 1200,
     
    };
  },
  async mounted() {
    this.$store.dispatch("getStudy");
    this.$store.commit("SET_LOGINSTATUS", localStorage.getItem("user") ? true : false);
    await this.getData();
    // this.$nextTick(()=>{
    //   this.width = this.$refs.index.offsetWidth

    // })
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    indexData() {
      console.log("this.$store.state.plat", this.$store.state.plat.logo);
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.$store.state.plat.indexData;
      } else {
        let arr = this.category.map((e) => {
          return e;
        });
        arr.push({
          category_id: 0,
          category_name: "全部分类",
        });
        return arr;
      }
    },
    liveImg() {
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.liveImg1;
      } else {
        return this.liveImg2;
      }
    },
  },
  watch:{
    plat(){
      // this.$store.dispatch("getStudy");
      // this.$store.commit("SET_LOGINSTATUS", localStorage.getItem("user") ? true : false);
      this.getData()
    }
  },
  methods: {
    tojiazhang() {
      console.log(window.location);
      let url = 'https://www.edufumu.com/';
      window.open(url);
    },
    // 去列表
    toList(url, id, index) {
      let myUrl = `/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
    },
    ideaNavChange(index) {
      this.ideaActive = index;
      let numList = [];
      while (numList.length < 3) {
        let num = parseInt(Math.random() * this.ideaAllData.length);
        if (numList.indexOf(num) == -1) {
          numList.push(num);
        }
      }
      (this.ideaData = []),
        (this.ideaData = [
          this.ideaAllData[numList[0]],
          this.ideaAllData[numList[1]],
          this.ideaAllData[numList[2]],
        ]);
    },
    jumpList(name) {
      this.$until.toPageOpen("/" + name);
      // this.$router.push(`./${name}`);
    },
    jumpBannerLesson(item) {
      if (!this.isLogin) {
        this.$store.commit("changeLoginShow", true);
        return;
      }
      this.$until.toPageOpen(`/detail2?id=${item.url}`);
      // this.$router.push(`./detail?id=${item.url}`);
    },
    jumpLesson(item) {
      console.log("detail");
      if (!this.isLogin) {
        this.$store.commit("changeLoginShow", true);
        return;
      }
      this.$until.toPageOpen(`/detail2?id=${item.id}`);
    },
    jumpIdea(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    will(index) {
      console.log(this.plat);
      if (this.plat == "119" || this.plat == "118") {
        this.$toast("敬请期待");
      } else {
        this.$router.push("./lesson?index=" + index);
      }
    },
    async getData() {
      this.pageLoading = true;
      
      let res = await this.$api({
        method: "get",
        url: "/api/platform/zhong/index",
        data: {
          flat_id: this.plat,
          is_interior: 1,
          terminal: 1, //1 pc 2 小程序
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        this.banner = res.data.banner || [];
        let category = res.data.category || [];
        this.jnLesson = category.find((item) => item.category_id == 206).goods;
        let vipInfo = res.data.plat_vip;
        this.$store.commit("SET_VIP", vipInfo);
      } else {
        this.$toast(res.msg);
      }
    },
    play(item) {
      let { video_id, ccid, name, url, goods_id, id } = item;
      console.log(video_id, ccid);
      let type = "ali";
      if (!video_id && ccid) {
        type = "cc";
        id = ccid;
      } else if (url) {
        type = "url";
      } else {
        this.$toast("没有视频");
        return;
      }
      this.$router.push(
        `./player?id=${id}&videoId=${video_id}&name=${name}&type=${type}&from=课程&url=${encodeURI(
          url
        )}&goods_id=${goods_id}`
      );
    },
    liveplay(item) {
      if (!this.isLogin) {
        this.$store.commit("changeLoginShow", true);
        return;
      }
      let { name, video_url, id, video_id, goods_id } = item;

      if (video_url || video_id) {
        this.$store.commit("SET_VIDEO", null);
        let url = `/player?id=${id}&videoId=${video_id}&name=${name}&url=${encodeURI(
          video_url
        )}&from=直播&goods_id=${goods_id}&type=ali`;
        this.$until.toPageOpen(url);
      } else {
        this.$toast("没有视频");
      }

      // this.$api({
      //   method: "get",
      //   url: "/api/livestream/click",
      //   data: {
      //     id,
      //   },
      // });
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.$store.commit("SET_PLAT", to.params.plat_type);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #dedee4;
  border-radius: 5px;
  margin: 5px 0;
}
body{
  background: #fafafa;
}
</style>
<style lang="scss" scoped>
.index {
  width: 100%;
  .index-main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    height: 372px;
    // align-items: flex-start;
    .userInfo {
      width: 180px;
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userBg.jpg");
      display: flex;
      flex-direction: column;
      align-items: center;
      .userLogo {
        padding-top: 20px;
        text-align: center;
        padding-bottom: 10px;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: block;
          margin: 0 auto 5px;
        }
        span {
          font-size: 16px;
          color: 393939;
          // font-family: "黑体";
        }
      }
      .studyTime {
        margin-bottom: 80px;
        p {
          font-size: 12px;
          width: 122px;
          display: flex;
          height: 58px;
          align-items: center;
          b {
            flex: 1;
            font-size: 22px;
            text-align: right;
            padding-right: 5px;
          }
        }
      }
      .btn {
        cursor: pointer;
        width: 148px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        background: #0080d6;
      }
    }
    .noLogin {
      width: 180px;
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userNoLogin.png");
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn {
        margin-top: 296px;
        width: 148px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        background: #0080d6;
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: center;
        span {
          width: 1px;
          height: 15px;
          margin: 0 10px;
          display: block;
          background: #fff;
        }
        p {
          cursor: pointer;
        }
      }
    }
  }

  .banner {
    flex: 1;
    margin-right: 29px;
    overflow: hidden;
    height: 100%;
    .banner-item {
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .hot {
    width: 100%;
    margin: 16px auto 0;
  }
  .module-title {
    font-size: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 41px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-family: "SourceHanSans";
    }
    span {
      color: #006bb3;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .course-card2 {
    margin-top: 87px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 100vw;
      min-width: 1200px;
      height: 100%;
      background: #fff;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
    }
    .module-title {
      padding-top: 42px;
      padding-bottom: 11px;
    }
    @keyframes toScale {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.2);
      }
    }
    @keyframes toScaleSmall {
      from {
        transform: scale(1.2);
      }
      to {
        transform: scale(1);
      }
    }
    .course-cover {
      ::v-deep img {
        cursor: pointer;
      }
    }
    .imgBig{
      ::v-deep img {
        animation: toScale 0.3s;
        transform: scale(1.2);
      }
      
    }
    .imgSmall{
      ::v-deep img {
        animation: toScaleSmall 0.3s;
        transform: scale(1);
      }
      
    }
  
    .course-card-cover {
      width: 560px;
      height: 315px;
      border-radius: 10px;
      overflow: hidden;
    }
    .course-card-detail {
      flex: 1;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      .course-card-title {
        font-size: 20px;
        font-weight: bold;
      }
      .detail {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #666666;
        line-height: 26px;
      }
      .lessonTime {
        flex: 1;
        font-size: 16px;
        color: #006bb3;
      }
      > div {
        display: flex;
        justify-content: space-between;
        color: #7f7f7f;
        .price {
          color: #ff6c00;
          font-size: 12px;
          b {
            font-size: 24px;
          }
        }
      }
    }
  }
 
  .levelItem {
    .course-card-cover {
      height: auto;
      padding-bottom: 0;
      .course-cover {
        padding-bottom: 0;
        display: block;
      }
    }
    .course-card-detail {
      padding: 16px;
      .price {
        color: #ff6c00;
        font-size: 12px;
        b {
          font-size: 24px;
          margin-right: 8px;
        }
        span {
          color: #7f7f7f;
          display: block;
          text-decoration: line-through;
          display: inline;
          display: none;
        }
      }
      .course-card-title {
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 4px;
        font-size: 16px;
        overflow: auto;
        height: 80px;
        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 24px;
          color: #333333;
          font-size: 16px;
        }
        .des {
          font-size: 12px;
          color: #7f7f7f;
        }
        .lessonTm {
          font-size: 12px;
          color: #006bb3;
          font-weight: bold;
        }
      }
    }
  }
  .course-item {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .course-list {
    padding-top: 10px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      border-radius: 8px;
      margin-right: 20px;
      margin-bottom: 20px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
}

</style>

<style lang="scss">
.index {
  .van-skeleton__row {
    height: 26px;
    margin-top: 20px;
  }
}
</style>
<style scoped lang="scss">
.index {
  width: 1200px !important;
  margin: 0 auto;
}
</style>
