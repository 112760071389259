<template>
    <div class="course-card2" :class="{paddingBottom: padBottom}">
      <div class="module-title">
        <img
          :src="imgUrl"
        />
        <p>{{ name }}</p>
        <span @click="toList('LessonTuoyu', 189)" v-if="id">更多
          <van-icon name="arrow" color="#006bb3" size="16" />
        </span>
      </div>
      <div
        class="course-card2-item"
        v-for="card of list"
        :key="card.id"
        @click="jumpLesson(card)"
      >
        <div class="course-card-cover" @mousemove="imgScaleIndex=card.id"
            @mouseout="imgScaleIndex=''">
          <div class="tip">
            <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
            <img :src="$free" v-if="card.is_free == 2" />
            <img :src="$vip" v-else-if="card.is_vip_flat == 1" />
            <img :src="$noVip" v-else />
          </div>
          <van-image
            class="course-cover"
            :class="{imgBig:imgScaleIndex == card.id,imgSmall:imgScaleIndex != card.id}"
            fit="cover"
            width="100%"
            height="100%"
            :error-icon="$placeImg"
            :loading-icon="$placeImg"
            :src="card.cover_mobile"
          >
            <template v-slot:error>
              <van-image
                style="width: 100%; height: 100%"
                fit="cover"
                src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
              ></van-image>
            </template>
          </van-image>
        </div>
        <div class="course-card-detail">
          <p class="course-card-title">{{ card.name }}</p>
          <p class="detail">{{ card.description }}</p>

          <p class="lessonTime">{{ card.master_name}}</p>
          <div v-if="!onlyExamin">
            <p class="price" v-if="!$store.state.plat.priceHide">
                ￥<b>{{ card.price }}</b>
            </p>
            <p class="study-num">{{ card.ordernum }}人已学习</p>
            <p class="btn" :style="{color: styleInfo && styleInfo.btnColor || '#006bb3' }">立即学习</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "index",
  props:['list', 'name', 'imgUrl','id','tag' ,'padBottom','onlyExamin','styleInfo'],
  data() {
    return {
      vipIndex: 0,
      width: 1200,
      imgScaleIndex: '',
    };
  },
  async mounted() {
    
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    indexData() {
      console.log("this.$store.state.plat", this.$store.state.plat.logo);
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.$store.state.plat.indexData;
      } else {
        let arr = this.category.map((e) => {
          return e;
        });
        arr.push({
          category_id: 0,
          category_name: "全部分类",
        });
        return arr;
      }
    },
    
  },
  methods: {
   // 去列表
   toList(url, id, index) {
      let myUrl = `/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
      
    },
  
    jumpLesson(item) {
      if(this.$until.loginRequire()){
        this.$until.toPageOpen(`/detail2?id=${item.id}`)
      }
      // this.$until.toPageOpen(`/detail2?id=${item.id}`);
    },
    
  },
  beforeRouteUpdate(to, from, next) {
   
  },
};
</script>

<style lang="scss" scoped>
  .paddingBottom{
    padding-bottom: 48px;
  }
  .course-card2 {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    &::before {
      display: none;
    }
    .module-title {
      padding-top: 42px;
      padding-bottom: 41px;
      font-size: 36px;
      display: flex;
      width: 100%;
      align-items: center;
      padding-top: 89px;
      img {
        margin-right: 10px;
      }
      p {
        flex: 1;
        font-family: "SourceHanSans";
      }
      span {
        color: #006bb3;
        font-size: 16px;
        cursor: pointer;
      }
    }
    @keyframes toScale {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.2);
      }
    }
    @keyframes toScaleSmall {
      from {
        transform: scale(1.2);
      }
      to {
        transform: scale(1);
      }
    }
    .course-cover {
      ::v-deep img {
        cursor: pointer;
      }
    }
    .imgBig{
      ::v-deep img {
        animation: toScale 0.3s;
        transform: scale(1.2);
      }
      
    }
    .imgSmall{
      ::v-deep img {
        animation: toScaleSmall 0.3s;
        transform: scale(1);
      }
      
    }
    .course-card2-item {
      width: calc((100% - 26px) / 2);
      float: left;
      cursor: pointer;
      display: flex;
      height: 210px;
      &:nth-of-type(2n+1){
        margin-left: 20px;
      }
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
    }
    .course-card-cover {
      width: 280px;
      height: 210px;
      border-radius: 10px;
      overflow: hidden;
    }
    .course-card-detail {
      flex: 1;
      padding: 24px 20px;
      display: flex;
      flex-direction: column;
      .course-card-title {
        font-size: 16px;
        color: #000;
        font-weight: bold;
      }
      .detail {
        padding-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #7f7f7f;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
      }
      .lessonTime {
        flex: 1;
        font-size: 16px;
        color: #006bb3;
      }
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #7f7f7f;
        .price {
          color: #ff6c00;
          font-size: 12px;
          b {
            font-size: 24px;
          }
        }
        .btn{
          height: 34px;
          width: 115px;
          border-radius: 4px;
          text-align: center;
          line-height: 34px;
          background-color: #f7f7f7;
          color: #144094;
          font-size: 14px;
          &:hover{
            background-color: #144094;
            color: #fff !important;
          }
        }
        .study-num{
          font-size: 14px;
          color: #7f7f7f;
        }
      }
    }
  }
</style>
