<template>
  <div class="course-item">
    <div class="module-title" v-if="name">
      <img :src="imgUrl" />
      <p>{{ name }}</p>
      <span v-if="id" @click="toList('LessonTuoyu', id)"
        >更多
        <van-icon name="arrow" color="#144094" size="16" />
      </span>
    </div>
    <div class="course-list">
      <div
        class="course-card"
        style="width: calc((100% - 60px) * 0.25)"
        v-for="card of list"
        :key="card.id"
        @click="jumpLesson(card)"
      >
        <div
          class="course-card-cover"
          :style="{ height: ((width - 60) * 0.25 * 9) / 16 + 'px' }"
        >
          <div class="tip">
            <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
            <img :src="$free" v-if="card.is_free == 2" />
            <img :src="$vip" v-else-if="card.is_vip_flat == 1" />
            <img :src="$noVip" v-else />
          </div>
          <van-image
            class="course-cover"
            fit="cover"
            width="100%"
            :style="{ height: ((width - 60) * 0.25 * 9) / 16 + 'px' }"
            :error-icon="$placeImg"
            :loading-icon="$placeImg"
            :src="card.cover_mobile"
          >
            <template v-slot:error>
              <van-image
                style="width: 100%; height: 100%"
                fit="cover"
                :src="$placeImg"
              ></van-image>
            </template>
          </van-image>
        </div>
        <div class="course-card-detail">
          <p>{{ card.name }}</p>
          <p class="des ellipsis_three">{{ card.description }}</p>
          <p class="lessonTm">
            <!-- 共计{{ card.study_times }}学时 -->
          </p>
          <p class="btn">{{ btnText || "免费学习" }}</p>
          <!-- <p class="price">
              ￥<b>{{ card.price }}</b
              ><span>￥{{ card.express_price }}</span>
            </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myAudio from "@/components/Audio";
export default {
  name: "Home",
  components: { myAudio },
  props: ["list", "name", "imgUrl", "id", "btnText"],
  data() {
    return {
      width: 1200,
      finished: false,
      isShowAudioPlayer: false,
      currentInfo: null,
      currentIndex: 0,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    indexData() {
      console.log("this.$store.state.plat", this.$store.state.plat.logo);
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.$store.state.plat.indexData;
      } else {
        let arr = this.category.map((e) => {
          return e;
        });
        arr.push({
          category_id: 0,
          category_name: "全部分类",
        });
        return arr;
      }
    },
    liveImg() {
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.liveImg1;
      } else {
        return this.liveImg2;
      }
    },
  },
  mounted() {},

  methods: {
    // 去列表
    toList(url, id, index) {
      // if (!this.user) this.$toast("请登录");
      let myUrl = `/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
    },
    jumpLesson(item) {
      console.log("detail");
      if (this.$until.loginRequire()) {
        this.$until.toPageOpen(`/detail2?id=${item.id}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.module-title {
  font-size: 36px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 89px;
  padding-bottom: 41px;
  img {
    margin-right: 10px;
  }
  p {
    flex: 1;
    font-weight: bold;
    font-family: "SourceHanSans";
  }
  span {
    color: #006bb3;
    font-size: 16px;
    cursor: pointer;
  }
}

.course-item {
  position: static;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .levelTitle {
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    p {
      cursor: pointer;
      font-size: 16px;
      color: #0080d6;
    }
  }
  .course-card {
    margin-right: 20px;
    height: 355px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    &:nth-of-type(4n + 4) {
      margin-right: 0;
    }
    margin-bottom: 20px;
    .course-card-cover {
      height: auto;
      padding-bottom: 0;
      .course-cover {
        padding-bottom: 0;
        display: block;
        flex-shrink: 0;
      }
    }
    .course-card-detail {
      flex: 1;
      padding: 16px;
      display: flex;
      flex-direction: column;
      .price {
        color: #ff6c00;
        font-size: 12px;
        b {
          font-size: 24px;
          margin-right: 8px;
        }
        span {
          color: #7f7f7f;
          display: block;
          text-decoration: line-through;
          display: inline;
          display: none;
        }
      }
      line-height: 24px;
      margin-bottom: 4px;
      font-size: 16px;
      p:first-of-type {
        font-weight: bold;
      }
      p {
        width: 100%;
        color: #333333;
        font-size: 16px;
      }
      .des {
        margin-top: 5px;
        flex: 1;
        font-size: 14px;
        color: #7f7f7f;
        margin-bottom: 10px;
      }
      .lessonTm {
        font-size: 14px;
        color: #144094;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .btn {
        height: 40px;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
        background-color: #f7f7f7;
        color: #144094;
        &:hover {
          background-color: #2e66d0;
          color: #fff;
        }
      }
    }
  }
}
.course-list {
  padding-top: 10px;
  position: static;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  > div {
    border-radius: 8px;
    margin-right: 20px;
    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }
}
</style>

<style lang="scss"></style>
