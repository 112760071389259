<template>
  <div>
    <tuoyuIndex v-if="$route.params.plat_type =='tuoyu'"></tuoyuIndex>
    <yingyouIndex v-else-if="$route.params.plat_type =='yingyou'"></yingyouIndex>
    <jiazhangIndex v-else-if="$route.params.plat_type =='jiazhang06'"></jiazhangIndex>
    <zhongkeIndex v-else-if="$route.params.plat_type =='zhongke'"></zhongkeIndex>
    <jiazhang03Index v-else-if="$route.params.plat_type =='jiazhang03'"></jiazhang03Index>
    <div class="index" v-loading="pageLoading" ref="index"  v-else-if="$route.params.plat_type">
    <div class="index-main">
      <div class="classification">
        <ul class="list">
          <li
            class="list-item"
            v-for="(item, index) of category"
            :key="index"
            @click="toList('lesson',index)"
          >
            <span>
              {{ item.category_name }}
            </span>
            <i class="el-icon-arrow-right"></i>
          </li>
          <!-- <li
            class="list-item"
           
            @click="toList('lesson')"
          >
            <span>
              更多
            </span>
            <i class="el-icon-arrow-right"></i>
          </li> -->
        </ul>
      </div>
      <van-swipe class="banner" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) of banner" :key="index">
          <van-image
            class="banner-item"
            width="100%"
            :src="item.cover"
            @click="jumpBannerLesson(item)"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="category idea" v-if="category.length">
      <div class="module-title">{{$store.state.plat_id == '107' || $store.state.plat_id == '121' ? 'VIP课程' : '基础课程'}}</div>
      <div
        class="course-list"
        v-for="(item, index) of category"
        :key="item.category_id"
        style="flex-wrap: wrap"
        v-if="item.goods.length"
      >
      <!-- style="height:calc((100% - 60px)*0.25*0.5625)" -->
      <div class="levelCate course-card" v-if="item.category_id" @click="toList('lesson',index)" :style="{height:(width- 60)*0.25*9/16+32+48+'px',backgroundImage:'url('+item.cover+')'}">
        <!-- <b>{{item.category_name}}</b>
          <p>查看全部<i class="el-icon-arrow-right"></i></p> -->
      </div>
        <div
          class="course-card levelItem"
          style="width:calc((100% - 60px)*0.25)"
          v-for="card of item.goods"
          :key="card.id"
          @click="jumpLesson(card)"
        >
          <div class="course-card-cover">
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="card.is_free==2"/>
              <img :src="$vip" v-else-if="card.is_vip_flat==1"/>
              <img :src="$noVip" v-else>
            </div>
            <van-image
              class="course-cover"
              width="100%"
              :style="{height:(width- 60)*0.25*9/16+'px'}"
              :error-icon="$placeImg"
              :loading-icon="$placeImg"
              :src="card.cover_mobile"
            >
                <template v-slot:error>
                  <van-image style="width: 100%; height: 100%" fit="cover" src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"></van-image>
                </template>
              </van-image>
          </div>
          <div class="course-card-detail">
            <div class="course-card-title">
              <p>{{ card.name }}</p><span>￥{{card.price}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hot" v-if="($store.state.plat_id == '118' || $store.state.plat_id == '119' )&& demonstrate.length" style="marginTop:0">
      <div class="module-title">示范课</div>
      <div
        class="course-list"
        v-for="(item, index) of demonstrate"
        v-if="item.goods.length"
        :key="item.category_id"
        style="flex-wrap: wrap"
      >
      <div class="levelCate course-card" v-if="item.category_id" @click="toList('demonstration',index)" :style="{height:(width- 60)*0.25*9/16+32+48+'px',backgroundImage:'url('+item.cover+')'}">
          <!-- <img :src="item.cover"/> -->
          <!-- <b>{{item.category_name}}</b>
          <p>查看全部<i class="el-icon-arrow-right"></i></p> -->
      </div>
        <div
          class="course-card levelItem"
          style="width:calc((100% - 60px)*0.25)"
          v-for="card of item.goods"
          :key="card.id"
          @click="jumpLesson(card)"
        >
          <div class="course-card-cover">
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="card.is_free==2"/>
              <img :src="$vip" v-else-if="card.is_vip_flat==1"/>
              <img :src="$noVip" v-else>
            </div>
            <van-image
              class="course-cover"
              width="100%"
              :style="{height:(width- 60)*0.25*9/16+'px'}"
              :error-icon="$placeImg"
              :loading-icon="$placeImg"
              :src="card.cover_mobile"
            >
                <template v-slot:error>
                  <van-image style="width: 100%; height: 100%" fit="cover" src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"></van-image>
                </template>
              </van-image>
          </div>
          <div class="course-card-detail">
            <div class="course-card-title">
              <p>{{ card.name }}</p><span>￥{{card.price}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="idea" v-if="liveData.length">
      <div class="module-title">
        直播专栏
        <span @click="jumpList('live')"
          >更多
          <van-icon name="arrow" color="#999" size="16" />
        </span>
      </div>
      <div class="course-list" v-if="v.data.length" v-for="(v,index) of liveData" :key="index">
        <div class="levelCate course-card"  @click="toList('live',index)" :style="{height:(width- 60)*0.25*9/16+32+48+'px',backgroundImage:v.cover?'url('+v.cover+')' : 'url('+liveImg[index]+')'}">
          <!-- <b>{{v.category_name}}</b>
          <p>查看全部<i class="el-icon-arrow-right"></i></p> -->
        </div>
        <div
          class="course-card levelItem"
          style="width:calc((100% - 60px)*0.25)"
          v-for="item of v.data"
          :key="item.id"
          @click="liveplay(item)"
        >
          <div class="course-card-cover">
            <van-image class="course-cover" width="100%" :style="{height:(width- 60)*0.25*9/16+'px'}" :src="item.img_url">
                <template v-slot:error>
                  <van-image style="width: 100%; height: 100%" fit="cover" src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"></van-image>
                </template>
              </van-image>
            <span class="course-card-cover-tag">直播</span>
          </div>
          <div class="course-card-detail">
            <div class="course-card-title">
              <p>{{ item.name }}</p>
            </div>
            <div class="card-btn">观看回放</div>
          </div>
        </div>
      </div>
    </div>
  
    
    
  </div>
  </div>
  
</template>

<script>
import tuoyuIndex from './IndexTuoyu'
import yingyouIndex from './Index2'
import jiazhangIndex from './Index3'
import jiazhang03Index from './IndexJiazhang03'

import zhongkeIndex from './IndexZhongke'
document.ondragstart = function () {
  return false;
};
export default {
  name: "index",
  components:{tuoyuIndex, jiazhangIndex, zhongkeIndex,jiazhang03Index, yingyouIndex},
  data() {
    return {
      active: 0,
      pageLoading: true,
      hotData: [],
      topData: [],
      goodData: [],
      certificate: [],
      ideaActive: 0,
      ideaNavData: [],
      ideaData: [],
      liveData: [],
      certData: [],
      loading: true,
      checked: false,
      ideaAllData: [],
      category: [],
      banner:[],
      demonstrate:[],
      width:1200,
      baseLessonImg:[
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%9F%BA%E7%A1%80%E8%AF%BE-%E4%BF%9D%E8%82%B2%E4%BF%9D%E6%95%99.png',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%9F%BA%E7%A1%80%E8%AF%BE-%E5%8D%AB%E7%94%9F%E4%BF%9D%E5%81%A5.png',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%9F%BA%E7%A1%80%E8%AF%BE-%E5%90%8E%E5%8B%A4%E5%B7%A5%E4%BD%9C.png',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%9F%BA%E7%A1%80%E8%AF%BE-%E5%AE%B6%E9%95%BF%E5%B7%A5%E4%BD%9C.png',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%9F%BA%E7%A1%80%E8%AF%BE-%E6%8B%9B%E7%94%9F%E5%B7%A5%E4%BD%9C.png',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%9F%BA%E7%A1%80%E8%AF%BE-%E8%A1%8C%E6%94%BF%E5%B7%A5%E4%BD%9C.png'
      ],
      liveImg1:[
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%9B%B4%E6%92%AD%E8%AF%BE-%E6%95%99%E7%A0%94.jpg',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%9B%B4%E6%92%AD%E8%AF%BE-%E8%BF%90%E8%90%A5%E7%AE%A1%E7%90%86.jpg'
      ],
      liveImg2:['https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%AE%B6%E9%95%BF%E5%A4%A7%E8%AE%B2%E5%A0%82.jpg']
    };
  },
  async mounted() {
    if(this.plat==119 || this.plat==171 || this.plat==203) return
    await this.getData();
    // this.$nextTick(()=>{
    //   this.width = this.$refs.index.offsetWidth

    // })
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
    indexData() {
      console.log("this.$store.state.plat", this.$store.state.plat.logo);
      if (
        this.$store.state.plat_id == "119" ||
        this.$store.state.plat_id == "118"
      ) {
        return this.$store.state.plat.indexData;
      } else {
        let arr = this.category.map((e) => {
          return e;
        });
        arr.push({
          category_id: 0,
          category_name: "全部分类",
        });
        return arr;
      }
    },
    liveImg(){
        if(this.$store.state.plat_id == "119" ||
        this.$store.state.plat_id == "118"){
          return this.liveImg1
        }else{
          return this.liveImg2
        }
    }
  },
  methods: {
    // 去列表
    toList(url,index){
       this.$router.push({
        path:`./${url}`,
        query:{
          index:index
        }
       })
    },
    ideaNavChange(index) {
      this.ideaActive = index;
      let numList = [];
      while (numList.length < 3) {
        let num = parseInt(Math.random() * this.ideaAllData.length);
        if (numList.indexOf(num) == -1) {
          numList.push(num);
        }
      }
      (this.ideaData = []),
        (this.ideaData = [
          this.ideaAllData[numList[0]],
          this.ideaAllData[numList[1]],
          this.ideaAllData[numList[2]],
        ]);
    },
    jumpList(name) {
      this.$router.push(`./${name}`);
    },
    jumpBannerLesson(item){
      this.$router.push(`./detail?id=${item.url}`);
    },
    jumpLesson(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    jumpIdea(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    will(index) {
      console.log(this.plat);
      if (this.plat == "119" || this.plat == "118") {
        this.$toast("敬请期待");
      } else {
        this.$router.push("./lesson?index=" + index);
      }
    },
    async getData() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/platform/pc/index",
        data: {
          plat_id: this.plat,
          terminal: 1
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        this.category = res.data.category || [];
        this.hotData = res.data.content_knowledge || [];
        this.topData = res.data.top_list || [];
        this.goodData = res.data.choice_course || [];
        this.certificate = res.data.certificate || [];
        this.liveData = res.data.live_stream || [];
        this.certData = res.data.certificate || [];
        this.banner = res.data.banner || [];
        this.demonstrate = res.data.demonstrate || [];
        this.$store.commit('SET_VIP',res.data.plat_vip)
      } else {
        this.$toast(res.msg);
      }
    },
    play(item) {
      let { video_id, ccid, name,url, goods_id, id } = item;
      console.log(video_id, ccid);
      let type = "ali";
      if (!video_id && ccid) {
        type = "cc";
        id = ccid;
      }else if(url) {
        type = "url";
      }else{
        this.$toast('没有视频')
        return
      }
      this.$router.push(
        `./player?id=${id}&videoId=${video_id}&name=${name}&type=${type}&from=课程&url=${encodeURI(url)}&goods_id=${goods_id}`
      );
    },
    liveplay(item) {
      let { name, video_url,id,video_id ,goods_id } = item;
      
      if(video_url || video_id){
        this.$store.commit('SET_VIDEO',null)
          this.$router.push(
        `./player?id=${id}&videoId=${video_id}&name=${name}&url=${encodeURI(video_url)}&from=直播&goods_id=${goods_id}&type=ali`
      );
      }else{
          this.$toast('没有视频');
      }
      
      // this.$api({
      //   method: "get",
      //   url: "/api/livestream/click",
      //   data: {
      //     id,
      //   },
      // });
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.$store.commit("SET_PLAT", to.params.plat_type);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar{
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color:#F4F4F4;
  border-bottom: 1px solid #ECECEC;
  border-top: 1px solid #ECECEC
}
::-webkit-scrollbar-thumb{
 border-radius:5px;
  background-color: #DEDEE4;
  border-radius: 5px;
  margin: 5px 0;
}
body{
  background: #fafafa;
}
</style>
<style lang="scss" scoped>
.index {
  width: 100%;
  .index-main {
    width: 100%;
    margin: 16px auto 0;
    display: flex;
    align-items: flex-start;

    .classification {
      flex: 0 1 180px;
      width: 180px;
      height: 392px;
      box-shadow: 0 5px 10px rgb(51 51 51 / 6%);
      background: #fff;
      border-radius: 10px;
      margin-top: 16px;
      overflow: hidden;
      
      .list {
        padding: 8px 0;
        display: flex;
        height: 100%;
        box-sizing: border-box;
        flex-direction: column;
        li{
          flex:1;
          span{
            font-weight: bold;
          }
        }
        .list-item {
          position: relative;
          width: 100%;
          height: 37px;
          line-height: 37px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 16px;
          color: #333;
          font-size: 16px;
          box-sizing: border-box;

          &:hover {
            background: rgb(255, 107, 0, 0.05);
            color: rgb(164, 0, 1);
          }
          i{
            color: #C6C6C6;
          }
        }
      }
    }
  }

  .banner {
    flex: 0 1 calc(100% - 200px);
    width: calc(100% - 200px);
    margin: 16px 0 0 20px;
    overflow: hidden;

    .banner-item {
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .hot {
    width: 100%;
    margin: 16px auto 0;
  }
  .module-title{
    font-size: 32px;
  }
  .levelItem{
    .course-card-cover{
      height: auto;
      padding-bottom: 0;
      .course-cover{
        padding-bottom: 0;
        display: block;
      }
    }
    .course-card-detail{
      padding: 16px;
      .course-card-title {
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 4px;
        font-size: 16px;
        overflow: auto;
        margin-bottom: 0;
        p{
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 24px;
          font-size: 18px;
        }
        span{
          color: #a40001;
          display: block;
          height: 24px;
        }
      }
    }
  }
  .course-list {
    padding-top: 10px;
    padding-bottom: 20px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    >div{
      border-radius: 8px;
      margin-right: 20px;
      margin-bottom: 20px;
      &:nth-child(4n+4){
        margin-right: 0;
      }
    }
    
    .levelCate{
      cursor: pointer;
      width:calc((100% - 60px)*0.25);

      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      b{
        width: 143px;
        height: 35px;
        font-size: 36px;
        font-family: HarmonyOS Sans SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 62px;
        text-shadow: 0px 3px 0px #184A8C;
        padding-top: 39px;
        padding-left: 19px;
      }
      
      p{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: #DBDBDB;
        padding-left: 22px;
        align-items: center;
      }
    }
    .card-btn {
      cursor: pointer;
      border-radius: 6px;
      line-height: 28px;
      font-weight: 500;
      border-radius: 6px;
      color: #a40001;
      background: #f7f7f7;
      padding: 0 20px;
      font-size: 16px;
      flex: 0 0 auto;
      text-align: center;
      margin-top: 4px;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }
  }

  .idea,
  .cert {
    .idea-nav,
    .idea-list,
    .cert-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .idea-card {
      margin-top: 40px;
      width: calc((100% - 60px) / 3);
      margin-right: 20px;
      // height: 300px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      padding: 20px;
      height: 216px;

      .card-title {
        font-weight: 500;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-subtitle {
        overflow: hidden;
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 5;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .cert-card {
      width: calc((100% - 60px) / 3);
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0px 0px 10px #0000000f;

      .card-detail {
        background: #fff;
        padding: 16px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-cover {
        width: 100%;
        border-radius: 12px 12px 0 0;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        .tip{
          position: absolute;
          top:0;
          left:0px;
          z-index: 1;
          img{
            width: 78px;
            height: 35px;
          }
        }
      }

      .card-title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 16px;
        flex: 0 0 auto;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }

  .top-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    padding-bottom: 10px;
    .top-card {
      width: calc((100% - 80px) / 5);
      margin-right: 20px;
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 6%);
      cursor: pointer;
      flex: 0 0 calc((100% - 80px) / 5);

      &:hover {
        cursor: pointer;
        transform: translate(0, -6px);
        box-shadow: 0px 14px 36px rgb(0 0 0 / 6%);
        transition: all 0.3s ease;
      }

      .top-card-cover {
        width: 100%;
        height: 224px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        .tip{
          position: absolute;
          top:0;
          left:0px;
          z-index: 1;
          img{
            width: 50px;
            // height: 35px;
          }
        }
      }

      .course-card-detail {
        padding: 16px 16px 20px;

        .course-card-title {
          line-height: 24px;
          font-weight: 500;
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 16px;
        }

        .course-card-subtitle {
          line-height: 18px;
          margin-bottom: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 12px;
        }

        .course-card-btn-container {
          margin-top: 20px;
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 30px;

          .course-card-learners {
            font-size: 11px;
            line-height: 18px;
          }

          .course-card-btn {
            cursor: pointer;
            border-radius: 6px;
            line-height: 30px;
            font-weight: 500;
            border-radius: 6px;
            color: #a40001;
            background: #f7f7f7;
            padding: 0 20px;
            font-size: 14px;

            &:hover {
              color: #fff;
              background: #a40001;
            }
          }
        }
      }
    }
  }
  .category {
    // margin-top: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .index {
    .banner {
      width: calc(100% - 60px);
      margin: 16px auto 0;
    }
  }
}
</style>

<style lang="scss">
.index {
  .van-skeleton__row {
    height: 26px;
    margin-top: 20px;
  }
}
</style>
