var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageLoading),expression:"pageLoading"}],ref:"index",staticClass:"index"},[_c('div',{staticClass:"index-main"},[_c('div',{staticClass:"classification"},[_c('ul',{staticClass:"list"},[_vm._l((_vm.myCateList),function(item,index){return _c('li',{key:item.category_id,staticClass:"list-item",on:{"click":function($event){item.category_id?  _vm.toList('LessonTuoyu', item.category_id) : _vm.jumpLesson(item)}}},[_c('span',[_vm._v(" "+_vm._s(item.category_name)+" ")]),_c('i',{staticClass:"el-icon-arrow-right"})])}),_c('li',{staticClass:"list-item",on:{"click":function($event){return _vm.jumpList('liveTuoyu')}}},[_c('span',[_vm._v("往期直播回放 ")]),_c('i',{staticClass:"el-icon-arrow-right"})])],2)]),_c('el-carousel',{staticClass:"banner",attrs:{"interval":3000,"arrow":"hover","height":"372px"}},_vm._l((_vm.banner),function(item,index){return _c('el-carousel-item',{key:index},[_c('van-image',{staticClass:"banner-item",attrs:{"width":"100%","height":"372","src":item.cover,"fit":"cover"},on:{"click":function($event){return _vm.jumpBannerLesson(item)}}})],1)}),1),(_vm.isLogin)?_c('div',{staticClass:"userInfo"},[_c('div',{staticClass:"userLogo"},[_c('img',{attrs:{"src":(_vm.user && _vm.user.userface) ||
              'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userLogo.jpg'}}),_c('span',[_vm._v(_vm._s(_vm.user ? _vm.user.nickname : "用户昵称"))])]),_c('div',{staticClass:"studyTime"},[_c('p',[_vm._v(" 今日学习"),_c('b',[_vm._v(_vm._s(_vm.studyInfo.today_time))]),_vm._v("分钟 ")]),_c('p',[_vm._v(" 连续学习"),_c('b',[_vm._v(_vm._s(_vm.studyInfo.day_number))]),_vm._v("天 ")])]),_c('p',{staticClass:"btn",on:{"click":function($event){return _vm.jumpList('purchased2')}}},[_vm._v("进入已购")])]):_c('div',{staticClass:"noLogin"},[_c('div',{staticClass:"btn"},[_c('p',{on:{"click":function($event){return _vm.$store.commit('changeLoginShow', true)}}},[_vm._v("登录")]),_c('span'),_c('p',{on:{"click":function($event){return _vm.$store.commit('changeLoginShow', true)}}},[_vm._v("注册")])])])],1),(_vm.list.length)?_c('listTrain',{staticStyle:{"margin-top":"80px"},attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.list,"padBottom":true,"onlyExamin":true,"name":"全国托育服务技能大赛培训班","imgUrl":"https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg"}}):_vm._e(),(_vm.listFL.length)?_c('listCat',{staticStyle:{"margin-bottom":"80px"},attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.listFL,"btnText":"立即学习","name":"托育相关法律法规政策培训 ","id":208,"imgUrl":_vm.img1}}):_vm._e(),(_vm.lessonList3.length)?_c('IndexListNew',{attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"padBottom":true,"list":_vm.lessonList3.slice(0,2),"name":"托育机构负责人大纲培训","imgUrl":_vm.img2}}):_vm._e(),(_vm.lessonList4.length)?_c('listTrain',{attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.lessonList4.slice(0,2),"name":"托育机构保育人员大纲培训","imgUrl":_vm.img3}}):_vm._e(),(_vm.lessonList.length)?_c('listCat',{attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.lessonList,"name":"托育机构岗位职责培训","btnText":"立即学习","id":207,"imgUrl":_vm.img4}}):_vm._e(),(_vm.lessonList1.length)?_c('listTrain',{staticStyle:{"margin-top":"80px"},attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.lessonList1.slice(0,1),"name":"机构负责人技能提升培训","imgUrl":_vm.img5}}):_vm._e(),(_vm.lessonList2.length)?_c('listTrain',{attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.lessonList2.slice(0,1),"name":"保育人员技能提升培训","imgUrl":_vm.img6}}):_vm._e(),(_vm.sxList.length)?_c('listCat',{attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.sxList,"btnText":"立即学习","name":"托育技能实训室项目培训","id":226,"imgUrl":_vm.img7}}):_vm._e(),(_vm.jnLesson.length)?_c('listCat',{attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.jnLesson,"priceHide":true,"btnText":"立即学习","name":"托育从业人员职业发展","id":122,"imgUrl":_vm.img8}}):_vm._e(),(_vm.vipLesson.length)?_c('listCat',{attrs:{"styleInfo":{
        moreColor:'#144094',
        btnColor:'#144094'
      },"list":_vm.vipLesson,"name":"托育服务专项培训系列课程","id":163,"imgUrl":_vm.img9}}):_vm._e(),(_vm.liveData.length)?_c('div',{staticClass:"live"},[_c('div',{staticClass:"module-title"},[_c('img',{attrs:{"src":_vm.img10}}),_c('p',[_vm._v("往期直播回放")]),_c('span',{on:{"click":function($event){return _vm.jumpList('liveTuoyu')}}},[_vm._v("更多 "),_c('van-icon',{attrs:{"name":"arrow","color":"#144094","size":"16"}})],1)]),_c('div',{staticClass:"course-list"},_vm._l((_vm.liveData[0].data.slice(0, 4)),function(item){return _c('div',{key:item.id,staticClass:"course-card levelItem",staticStyle:{"width":"calc((100% - 60px) * 0.25)"},on:{"click":function($event){return _vm.liveplay(item)}}},[_c('div',{staticClass:"course-card-cover"},[_c('van-image',{staticClass:"course-cover",style:({ height: ((_vm.width - 60) * 0.25 * 9) / 16 + 'px' }),attrs:{"width":"100%","src":item.img_url},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('van-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"fit":"cover","src":"https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"}})]},proxy:true}],null,true)}),_c('span',{staticClass:"course-card-cover-tag"},[_vm._v("直播")])],1),_c('div',{staticClass:"course-card-detail"},[_c('div',{staticClass:"course-card-title"},[_c('p',[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"card-btn"},[_vm._v("观看回放")])])])}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }