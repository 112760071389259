<template>
 <div class="course-item">
      <div class="module-title">
        <img
          :src="imgUrl"
        />
        <p>{{name}}</p>
        <span @click="toList('vipLesson', id)"
          >更多
          <van-icon name="arrow" color="#006bb3" size="16" />
        </span>
      </div>
      <div class="course-list">
        <div
          class="course-card levelItem"
          style="width: calc((100% - 60px) * 0.25)"
          v-for="card of list.slice(0, 4)"
          :key="card.id"
          @click="jumpLesson(card)"
        >
          <div
            class="course-card-cover"
            :style="{ height: ((width - 60) * 0.25 * 9) / 16 + 'px' }"
          >
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="card.is_free == 2" />
              <img :src="$vip" v-else-if="card.is_vip_flat == 1" />
              <img :src="$noVip" v-else />
            </div>
            <van-image
              class="course-cover"
              width="100%"
              :style="{ height: ((width - 60) * 0.25 * 9) / 16 + 'px' }"
              :error-icon="$tuoyuPlaceImg"
              :loading-icon="$tuoyuPlaceImg"
              :src="card.cover_mobile"
            >
              <template v-slot:error>
                <van-image
                  style="width: 100%; height: 100%"
                  fit="cover"
                  :src="$tuoyuPlaceImg"
                ></van-image>
              </template>
            </van-image>
          </div>
          <div class="course-card-detail">
            <div class="course-card-title">
              <p>{{ card.name }}</p>
              <p class="des">{{ card.description }}</p>
              <!-- <p class="lessonTm">共计{{ card.study_times }}学时（线上 学时，线下 学时）</p> -->
            </div>
            <div class="info">
              <p>{{ card.study_times }}人已学习</p>
              <p class="price">
                ￥<b>{{ card.price }}</b><span>￥{{ card.express_price }}</span>
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import myAudio from '@/components/Audio'
export default {
  name: "Home",
  components:{myAudio},
  props:['list','name','imgUrl','id'],
  data() {
    return {
      width: 1200,
      finished: false,
      isShowAudioPlayer:false,
      currentInfo:null,
      currentIndex:0,
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    indexData() {
      console.log("this.$store.state.plat", this.$store.state.plat.logo);
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.$store.state.plat.indexData;
      } else {
        let arr = this.category.map((e) => {
          return e;
        });
        arr.push({
          category_id: 0,
          category_name: "全部分类",
        });
        return arr;
      }
    },
    liveImg() {
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.liveImg1;
      } else {
        return this.liveImg2;
      }
    },
  },
  mounted() {

  },

  methods: {
     // 去列表
     toList(url, id, index) {
      // if (!this.user) this.$toast("请登录");
      let myUrl = `/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
    },
    jumpLesson(item) {
      console.log("detail");
      if (!this.isLogin) {
        this.$store.commit('changeLoginShow', true)
        return
      };
      this.$until.toPageOpen(`/detail2?id=${item.id}`);
    },
  },
  

};
</script>

<style lang="scss" scoped>
  .module-title {
    font-size: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 20px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-weight: bold;
      font-family: "SourceHanSans";
      color: #333333;
    }
    span {
      color: #006bb3;
      font-size: 16px;
      cursor: pointer;
    }
  }
  
  .levelItem {
    .course-card-cover {
      height: auto;
      padding-bottom: 0;
      .course-cover {
        padding-bottom: 0;
        display: block;
      }
    }
    .course-card-detail {
      padding: 16px;
      .info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #a5a5a5;
        font-size: 12px;
      }
      .price {
        color: #ff6c00;
        font-size: 12px;
        b {
          font-size: 24px;
          margin-right: 8px;
        }
        span {
          color: #7f7f7f;
          display: block;
          text-decoration: line-through;
          display: inline;
          // display: none;
        }
      }
      .course-card-title {
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 4px;
        font-size: 16px;
        overflow: auto;
        height: 80px;
        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 24px;
          color: #333333;
          font-size: 16px;
        }
        .des {
          font-size: 12px;
          color: #7f7f7f;
          font-weight: normal;
        }
        .lessonTm {
          font-size: 12px;
          color: #006bb3;
          font-weight: bold;
        }
      }
    }
  }
  .course-item {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .levelTitle {
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      p {
        cursor: pointer;
        font-size: 16px;
        color: #0080d6;
      }
    }
    .course-card {
      margin-right: 20px;
      &:nth-of-type(4n + 4) {
        margin-right: 0;
      }
    }
    .course-card:nth-of-type(5n) {
      margin: 0;
    }
  }
  .course-list {
    padding-top: 10px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      border-radius: 8px;
      margin-right: 20px;
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }

    .levelCate {
      cursor: pointer;
      width: calc((100% - 60px) * 0.25);

      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      b {
        width: 143px;
        height: 35px;
        font-size: 36px;
        font-family: HarmonyOS Sans SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 62px;
        text-shadow: 0px 3px 0px #184a8c;
        padding-top: 39px;
        padding-left: 19px;
      }

      p {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: #dbdbdb;
        padding-left: 22px;
        align-items: center;
      }
    }
    .card-btn {
      cursor: pointer;
      border-radius: 6px;
      line-height: 28px;
      border-radius: 6px;
      color: #0080d6;
      background: #f7f7f7;
      padding: 0 20px;
      font-size: 16px;
      flex: 0 0 auto;
      text-align: center;
      margin-top: 4px;

      &:hover {
        color: #fff;
        background: #0080d6;
      }
    }
  }
</style>

<style lang="scss">

</style>
